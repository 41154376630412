import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'my-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './my-badge.component.html',
  styleUrl: './my-badge.component.css'
})
export class MyBadgeComponent {
  @Input() value: number = 0;
  @Input() label?: string;
  @Input() icon?: string;
  @Input() type?: 'success' | 'warning' | 'danger' | 'info' = 'success';
  @Input() size?: 'sm' | 'md' | 'lg' = 'md';

}
