<div
  class="
    flex 
    justify-center 
    items-center 
    gap-2 
    border
    border-gray-500 
    hover:border-gray-300 
    rounded-xl 
    px-1.5 
    py-0.5 
    mb-0.5 
    text-xs 
    {{
    type === 'success'
      ? 'bg-topvix-button text-white'
      : type === 'warning'
      ? 'bg-orange-100 text-orange-900'
      : type === 'danger'
      ? 'bg-red-500 text-red-100'
      : 'bg-gray-200  text-gray-700'
  }}
    group"
>
  <span *ngIf="icon" class="{{ icon }}"></span>
  <span *ngIf="label" class="text-xs font-normal">{{ label }}</span>
  <span
    *ngIf="value"
    class="
    flex 
    justify-center 
    items-center 
    w-5 h-5 
    text-xs
    font-extrabold 
    rounded-full 
    border-2 
    border-gray-500     
    group-hover:border-gray-300     
    {{
      type === 'success'
        ? 'bg-topvix-primary text-topvix-secondary'
        : type === 'warning'
        ? 'bg-orange-100 text-orange-900'
        : type === 'danger'
        ? 'bg-red-500 text-red-100'
        : 'bg-gray-200  text-gray-700'
    }}   
    group-hover:animate-pulse 
    group-hover:cursor-pointer"
    >{{ value }}</span
  >
</div>
